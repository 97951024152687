.language-selector {
  display: flex;
  justify-content: flex-end;
  width: min(100%, 440px);
  padding: 0 20px;

  .select-option-container {
    width: 90px;
  }
}

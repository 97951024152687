.image-container {
  width: 100%;
  height: 100%;

  picture {
    display: flex;
    width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &--sport-bonus {
    overflow: hidden;
    aspect-ratio: 72 / 24;
    border-radius: 15px;
  }
}

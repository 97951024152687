.signup-nav {
  display: flex;
  justify-content: center;
  gap: 0 30px;
  color: var(--c-white);
  font-size: 3em;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1;
  text-decoration: none;

  &__item {
    position: relative;

    @mixin bright-green-before {
      position: absolute;
      inset: auto -3px -6px;
      content: '';
      border-radius: 4px;
      height: 4px;
    }

    &::before {
      @include bright-green-before;

      background-color: transparent;
      transition: background-color 275ms ease;
    }

    &.is-active {
      cursor: default;
      transition: color 275ms ease;

      &::before {
        @include bright-green-before;

        background-color: var(--c-bright-green);
      }
    }

    &:not(.is-active) {
      &:hover {
        &::before {
          @include bright-green-before;

          background-color: var(--c-bright-green);
        }
      }
    }
  }

  &:hover {
    .signup-nav__item.is-active {
      &:not(:hover) {
        color: var(--c-spanish-gray);

        &::before {
          background-color: transparent;
        }
      }
    }
  }
}

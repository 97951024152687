.sport-bonus {
  display: grid;
  place-content: center;
  gap: 0;
  width: min(100vw - 40px, 490px);
  min-height: 100px;
  background-color: var(--c-white);
  border-radius: 15px;
  filter: drop-shadow(0 1vmin 2vmin #000000b3);

  &__image {
    position: relative;

    &-text {
      position: absolute;
      inset: 0;
      margin: auto;
      display: grid;
      place-content: center;

      .display-small {
        margin: auto;
        max-inline-size: auto;
        font-weight: 600;
        text-transform: uppercase;

        &:first-child {
          color: var(--c-white);
        }

        &:last-child {
          color: var(--c-lime);
        }

        span {
          font-size: 0.65em;
          font-weight: 700;
        }
      }
    }
  }
}

.dialog-lite-close-button {
  position: absolute;
  inset: 0 0 auto auto;
  margin: auto;
  z-index: 2;
  width: 50px;
  height: 50px;

  &::before {
    position: absolute;
    inset: 0;
    margin: auto;
    content: '';
    background-color: var(--c-white);
    width: 24px;
    height: 24px;
    border-radius: 100%;
  }

  .svg-icon {
    transform: scale(0.65);
  }
}

.current-bonus {
  display: grid;
  grid-template: 1fr / 52px 1fr;
  gap: 0;
  height: 100%;
  align-items: center;

  &__button {
    --c-icon: var(--c-white);

    display: grid;
    place-content: center;
    width: 52px;
    height: 52px;
    transform: translate(-16px, -12px);
    cursor: pointer;

    &:hover {
      --c-icon: var(--c-bright-green);
    }

    .svg-icon--info {
      width: 24px;
      height: 24px;
      fill: var(--c-icon);
    }
  }

  &__text {
    transform: translateX(-18px);
    color: var(--c-white);
    font-size: 14px;

    span {
      font-weight: 700;
    }
  }
}

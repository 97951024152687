.widget-signup {
  position: relative;
  z-index: var(--z-index-widget-signup);
  background: linear-gradient(to left, var(--c-black-sunset), var(--c-ebony-black));

  @include max(tablet-landscape) {
    overflow: hidden auto;
    -webkit-overflow-scrolling: touch;
    position: fixed;
    inset: auto 0 0;
    margin: auto;
    border-radius: 30px 30px 0 0;
    transition: transform 575ms cubic-bezier(0.5, 0, 0, 1);
    transform: translateY(110%);

    body.is-taking-bonus & {
      transform: translateY(0);
    }

    @supports (max-height: 100dvh) {
      max-height: calc(100dvh - 40px);
    }

    @supports not (max-height: 100dvh) {
      max-height: calc(100vh - 40px);
    }
  }

  @include min(tablet-landscape) {
    display: grid;
    place-content: center;
  }

  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--v-offset-sm) 0;
    padding: var(--v-offset-sm) 0;

    @include min(tablet-landscape) {
      justify-content: center;
    }
  }
}

.select-option {
  --select-dropdown-arrow: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgZmlsbD0ibm9uZSI+PHBhdGggZmlsbD0iIzI5NjJGRiIgZD0iTTEyIDE4Yy0uMzY2IDAtLjczMi0uMTQ2LTEuMDEtLjQ0bC04LjU3MS05YTEuNTUzIDEuNTUzIDAgMCAxIDAtMi4xMiAxLjM4IDEuMzggMCAwIDEgMi4wMiAwbDcuNTYgNy45NDEgNy41NjItNy45NGExLjM4IDEuMzggMCAwIDEgMi4wMiAwIDEuNTUzIDEuNTUzIDAgMCAxIDAgMi4xMmwtOC41NyA5YTEuMzkgMS4zOSAwIDAgMS0xLjAxMi40MzlaIi8+PC9zdmc+");
  --so-border-radius-select: 12.5px;
  --so-color-outline-inactive: transparent;
  --so-color-outline-hover: transparent;
  --so-color-outline-opened: transparent;
  --so-width-outline: 0;

  background-color: var(--c-white);
  font-size: 16px;

  &::after {
    width: 18px;
    height: 18px;
    background-size: 18px;
  }

  &--currency {
    .select-option-trigger,
    .select-option-list-item {
      padding-left: 54px;

      &--currency-rub {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIxOCIgZmlsbD0ibm9uZSI+PHBhdGggZmlsbD0iI2ZmZiIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMCAwaDI0djZIMFYwWiIgY2xpcC1ydWxlPSJldmVub2RkIi8+PHBhdGggc3Ryb2tlPSIjRDBEMEQwIiBkPSJNLjUuNWgyM3YxN0guNVYuNVoiLz48cGF0aCBmaWxsPSIjMDAzOUE2IiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0wIDZoMjR2NkgwVjZaIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiLz48cGF0aCBmaWxsPSIjRDUyQjFFIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0wIDEyaDI0djZIMHYtNloiIGNsaXAtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==");
      }

      &--currency-kzt {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIxOCIgZmlsbD0ibm9uZSI+PHBhdGggZmlsbD0iIzAxQTVDOCIgZD0iTTAgMGgyNHYxOEgweiIvPjxwYXRoIHN0cm9rZT0iI0ZGRTcwMCIgc3Ryb2tlLXdpZHRoPSIxLjI1IiBkPSJtOCAxMSA0LjUgM00xMS41IDE0bDQuNS0zIi8+PGNpcmNsZSBjeD0iMTIiIGN5PSI4IiByPSIzIiBmaWxsPSIjRkZFNzAwIi8+PC9zdmc+");
      }

      &--currency-rub,
      &--currency-kzt {
        background-position: center left 18px;
        background-size: 24px 18px;
        background-repeat: no-repeat;
      }
    }
  }

  &--lang {
    .select-option-trigger,
    .select-option-list-item {
      padding-left: 54px;

      &--lang-ru {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIxOCIgZmlsbD0ibm9uZSI+PHBhdGggZmlsbD0iI2ZmZiIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMCAwaDI0djZIMFYwWiIgY2xpcC1ydWxlPSJldmVub2RkIi8+PHBhdGggc3Ryb2tlPSIjRDBEMEQwIiBkPSJNLjUuNWgyM3YxN0guNVYuNVoiLz48cGF0aCBmaWxsPSIjMDAzOUE2IiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0wIDZoMjR2NkgwVjZaIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiLz48cGF0aCBmaWxsPSIjRDUyQjFFIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0wIDEyaDI0djZIMHYtNloiIGNsaXAtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==");
      }

      &--lang-kz {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIxOCIgZmlsbD0ibm9uZSI+PHBhdGggZmlsbD0iIzAxQTVDOCIgZD0iTTAgMGgyNHYxOEgweiIvPjxwYXRoIHN0cm9rZT0iI0ZGRTcwMCIgc3Ryb2tlLXdpZHRoPSIxLjI1IiBkPSJtOCAxMSA0LjUgM00xMS41IDE0bDQuNS0zIi8+PGNpcmNsZSBjeD0iMTIiIGN5PSI4IiByPSIzIiBmaWxsPSIjRkZFNzAwIi8+PC9zdmc+");
      }

      &--lang-ru,
      &--lang-kz {
        background-position: center left 18px;
        background-size: 24px 18px;
        background-repeat: no-repeat;
      }
    }

    .select-option-trigger {
      color: transparent;
    }
  }

  &--prefix {
    .select-option-trigger,
    .select-option-list-item {
      padding-left: 54px;

      &--prefix-ru {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIxOCIgZmlsbD0ibm9uZSI+PHBhdGggZmlsbD0iI2ZmZiIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMCAwaDI0djZIMFYwWiIgY2xpcC1ydWxlPSJldmVub2RkIi8+PHBhdGggc3Ryb2tlPSIjRDBEMEQwIiBkPSJNLjUuNWgyM3YxN0guNVYuNVoiLz48cGF0aCBmaWxsPSIjMDAzOUE2IiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0wIDZoMjR2NkgwVjZaIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiLz48cGF0aCBmaWxsPSIjRDUyQjFFIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0wIDEyaDI0djZIMHYtNloiIGNsaXAtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==");
      }

      &--prefix-kz {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIxOCIgZmlsbD0ibm9uZSI+PHBhdGggZmlsbD0iIzAxQTVDOCIgZD0iTTAgMGgyNHYxOEgweiIvPjxwYXRoIHN0cm9rZT0iI0ZGRTcwMCIgc3Ryb2tlLXdpZHRoPSIxLjI1IiBkPSJtOCAxMSA0LjUgM00xMS41IDE0bDQuNS0zIi8+PGNpcmNsZSBjeD0iMTIiIGN5PSI4IiByPSIzIiBmaWxsPSIjRkZFNzAwIi8+PC9zdmc+");
      }

      &--prefix-ru,
      &--prefix-kz {
        background-position: center left 18px;
        background-size: 24px 18px;
        background-repeat: no-repeat;
      }
    }

    .select-option-trigger {
      color: transparent;
    }
  }
}

.signup-support {
  display: flex;
  flex-direction: column;
  align-items: center;

  .title-medium {
    margin: 0 0 12px;
    font-weight: 500;
    font-size: 14px;
    color: var(--c-spanish-gray);
  }
}

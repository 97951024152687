.text-field-container {
  background-color: var(--c-white);
  color: var(--c-black-sunset);
  font-size: 16px;
  border-radius: 12.5px;

  input {
    width: 100%;
    height: 48px;
  }
}

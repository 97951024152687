.example-form-data { // TODO удалить демку
  display: none;
  position: fixed;
  inset: 0 auto auto 0;
  z-index: 99999;
  padding: 10px 14px;
  font-family: "Courier New", serif;
  font-size: 14px;
  background-color: black;
  color: lime;
  box-shadow: 0 0 0 2px inset black;
  animation: shadowColorChange 725ms infinite alternate ease-in-out;

  body.is-form-demo-sent & {
    display: block;
  }

  @keyframes shadowColorChange {
    from {
      box-shadow: 0 0 0 2px inset black;
    }

    to {
      box-shadow: 0 0 0 2px inset lime;
    }
  }
}

.signup-form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px 0;
  max-width: 440px;
  padding: 0 20px;

  body.is-form-demo-sent & { // TODO удалить демку
    transition: opacity 625ms ease-in-out, filter 625ms ease-in-out;
    opacity: 0.6;
    pointer-events: none;
    filter: grayscale(1) brightness(1);
  }
}

.signup-form__item[data-form="phone"] {
  position: relative;

  .text-field-container {
    width: calc(100% - 100px);
  }

  .phone-prefix-selector {
    position: absolute;
    inset: 0 0 0 auto;
    margin: auto;
    width: 90px;
  }
}






.text-field-container {
  position: relative;

  &.error {
    input {
      color: crimson;
    }
  }

  &.passed {
    input {
      color: #2DB62D;
    }
  }

  input {
    padding-left: 56px;
  }



  input::placeholder {
    color: dimgray;
  }

  input::input-placeholder {
    color: dimgray;
  }

  input:placeholder {
    color: dimgray;
  }

  input::placeholder {
    color: dimgray;
  }

  input:input-placeholder {
    color: dimgray;
  }

  input:focus::placeholder {
    color: silver;
  }

  input:focus::input-placeholder {
    color: silver;
  }

  input:focus:placeholder {
    color: silver;
  }

  input:focus::placeholder {
    color: silver;
  }

  input:focus:input-placeholder {
    color: silver;
  }
}

.svg-icon--email,
.svg-icon--phone,
.svg-icon--password {
  position: absolute;
  inset: 0 auto 0 18px;
  margin: auto;
  width: 24px;
  height: 24px;
  fill: gray;

  .error & {
    fill: crimson;
  }

  .passed & {
    fill: #2DB62D;
  }
}

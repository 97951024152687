.signup-tabs {
  display: flex;
  justify-content: space-between;
  gap: 0 10px;
  padding: 0 20px;
  width: min(100%, 440px);
  font-size: 15px;
  font-weight: 600;
  line-height: 1.2;
  text-align: center;

  @include min(tablet-portrait) {
    font-size: 1.9em;
  }

  &__item {
    display: flex;
    align-items: center;
    padding: 7px 12px;
    border-radius: 10px;
    user-select: none;
    color: var(--c-white);
    transition: background-color 125ms ease-in-out, color 125ms ease-in-out, box-shadow 125ms ease-in-out;

    &:hover {
      color: var(--c-bright-green);
      box-shadow: 0 0 0 1px inset var(--c-bright-green);
    }

    &:not(.is-active) {
      cursor: pointer;
    }

    &.is-active {
      background-color: var(--c-bright-green);
      color: var(--c-black-sunset);
    }
  }
}

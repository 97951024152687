.signup-payment {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px 20px;
  margin: 0 auto;
  padding: 0 30px;
  max-width: 450px;
  height: fit-content;

  &__item {

  }

  .svg-icon {
    &--payment {
      width: 6em;
      height: 3.24em;
      max-width: none;
      aspect-ratio: 100 / 54;
      fill: black;
    }
  }
}

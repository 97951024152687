.btn {
  display: grid;
  place-content: center;
  letter-spacing: .5px;
  white-space: nowrap;
  cursor: pointer;
  user-select: none;
  touch-action: manipulation;
  user-select: none;
  appearance: none;
  border: none;
  margin: 0;
  text-decoration: none;
  transition: background-color 255ms ease, color 255ms ease;

  &--take-bonus {
    width: min(80vw, 380px);
    min-width: fit-content;

    body.is-taking-bonus & {
      color: var(--c-black-sunset);
      background-color: var(--c-bright-green);
    }
  }

  &--send-form {
    width: 100%;
    min-width: fit-content;
    margin: auto;
  }

  &--purple {
    height: 64px;
    border-radius: 64px;
    padding: 0 28px;
    color: var(--c-white);
    background-color: var(--c-bright-violet);
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 600;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        color: var(--c-black-sunset);
        background-color: var(--c-bright-green);
      }
    }
  }

  &--blue-outline {
    width: fit-content;
    height: 38px;
    padding: 0 16px 0 40px;
    border: 1px solid var(--c-ultramarine-blue);
    color: var(--c-ultramarine-blue);
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgZmlsbD0ibm9uZSI+PHBhdGggZmlsbD0iIzI5NjJmZiIgZD0iTTE4LjI1NSAyLjIyYy0uMy0uMjU0LS43NzEtLjI5LTEuMjU4LS4wOTVoLS4wMDFjLS41MTIuMjA1LTE0LjUwNCA2LjIwNy0xNS4wNzQgNi40NTItLjEwNC4wMzYtMS4wMDguMzc0LS45MTUgMS4xMjYuMDgzLjY3OC44MS45NTkuODk5Ljk5MWwzLjU1NyAxLjIxOGMuMjM2Ljc4NiAxLjEwNiAzLjY4NCAxLjI5OSA0LjMwMy4xMi4zODYuMzE1Ljg5My42NTguOTk4LjMuMTE2LjYuMDEuNzk0LS4xNDJsMi4xNzQtMi4wMTcgMy41MTEgMi43MzguMDg0LjA1Yy4yMzguMTA1LjQ2Ny4xNTguNjg1LjE1OC4xNjggMCAuMzMtLjAzMi40ODQtLjA5NS41MjYtLjIxNi43MzYtLjcxNy43NTgtLjc3NGwyLjYyMy0xMy42M2MuMTYtLjcyOS0uMDYzLTEuMDk5LS4yNzgtMS4yODFaTTguNiAxMi40bC0xLjIgMy4yLTEuMi00IDkuMi02LjgtNi44IDcuNloiLz48L3N2Zz4=");
    background-position: left 12px center;
    background-repeat: no-repeat;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 600;
  }
}

.bonus-transfer {
  display: grid;
  grid-template: auto / repeat(3, 1fr);
  gap: 0 4vmin;
  color: var(--c-white);
  font-weight: 600;
  font-size: 11px;
  text-transform: uppercase;
  text-align: center;

  @include min(tablet-landscape) {
    font-size: 1.6em;
  }

  &__item {
    display: grid;
    grid-template: repeat(2, auto) / auto;
    gap: 10px 0;
    justify-items: center;
  }

  .svg-icon {
    width: 48px;
    height: 48px;
    fill: var(--c-bright-green);

    @include min(tablet-landscape) {
      width: 64px;
      height: 64px;
      fill: var(--c-bright-violet);
    }
  }
}

.banner-bg {
  position: fixed;
  inset: 0;
  margin: auto;

  &::before {
    pointer-events: none;
    content: '';
    position: absolute;
    inset: 0;
    margin: auto;
    background: linear-gradient(to bottom, transparent 20%, black 85%);
  }

  @include min(tablet-landscape) {
    position: absolute;
    inset: 0 auto 0 0;
    margin: auto;
    width: 64vw;

    &::before {
      pointer-events: none;
      content: '';
      position: absolute;
      inset: 0;
      margin: auto;
      background: linear-gradient(to left, transparent 20%, black 85%);
    }
  }
}

.tickbox--privacy,
.tickbox--mailing {
  --tickbox-checkbox-radius: 5px;
  --tickbox-label-font-color: var(--c-spanish-gray);

  margin: -10px 0;
  padding: 0;
  display: flex;

  label {
    margin-left: -10px;
    font-size: 14px;
    font-weight: 500;

    span:first-child {
      white-space: unset;
      text-overflow: unset;
      overflow: auto;
    }
  }

  a {
    text-decoration: none;
    color: var(--c-ultramarine-blue);

    &:hover {
      text-decoration: underline;
    }
  }
}

.banner-intro {
  position: relative;
  z-index: var(--z-index-banner-intro);
  color: var(--c-white);

  @include max(tablet-landscape) {
    max-width: 640px;
    margin: auto;
    transform: translate(-8vmin, 13vmin);
  }

  @include min(tablet-landscape) {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMDAiIGhlaWdodD0iMzAwIiBmaWxsPSJub25lIj48cGF0aCBmaWxsPSIjMkMyQzJDIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik01NS4zMjUgMEgxOThsLTM1LjE2MyAxMDUuOTUxaDI0LjE0NGwtMzEuNzczIDg2LjkyOWgyNS4wNjRMMTQ0LjUwOSAzMDBIMmwzNS4wNTUtMTA3LjgxOGgtMjQuMDdMNDQuNjYgMTA0LjYzSDE5LjY3NEw1NS4zMjUgMFoiIGNsaXAtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==");
    background-size: auto 100%;
    background-position: left 5vmin center;
    background-repeat: no-repeat;
  }

  &-p90 {
    position: absolute;
    inset: -3vmin -10% auto auto;
    margin: auto;
    width: min(calc(12vmin + 12vmax), 400px);
    height: min(calc(12vmin + 12vmax), 400px);
    pointer-events: none;
    background-image: url("../images/png/banner_p90.png");
    background-size: contain;
    background-position: left bottom;
    background-repeat: no-repeat;
    transform: translateY(calc(1.4px * var(--bias-y, 0))) translateX(calc(1.4px * var(--bias-x, 0)));
    transition: transform 4.5s linear;
    will-change: transform;

    @include min(tablet-landscape) {
      inset: 4vmin 20% auto auto;
    }
  }

  &-case {
    @include min(tablet-landscape) {
      position: absolute;
      inset: auto 10% -8vmin auto;
      margin: auto;
      width: min(calc(12vmin + 12vmax), 400px);
      height: min(calc(12vmin + 12vmax), 400px);
      pointer-events: none;
      background-image: url("../images/png/banner_case.png");
      background-size: contain;
      background-position: left bottom;
      background-repeat: no-repeat;
      transform: translateY(calc(1.5px * var(--bias-y, 0))) translateX(calc(1.5px * var(--bias-x, 0)));
      transition: transform 2s ease;
      will-change: transform;
    }
  }

  &-nus {
    position: absolute;
    inset: min(15vmin, 150px) min(5vmin, 50px) 0 min(8vmin, 80px);
    margin: auto;
    pointer-events: none;
    background-image: url("../images/png/banner_nus.png");
    background-size: contain;
    background-position: left bottom;
    background-repeat: no-repeat;

    @include min(tablet-portrait) {
      transform: translateY(-20px);
    }

    @include max(tablet-landscape) {
      aspect-ratio: 16 / 12;
    }
  }

  &-ops {
    pointer-events: none;
    background-image: url("../images/png/banner_ops.png");
    background-size: contain;
    background-position: left bottom;
    background-repeat: no-repeat;
    transform: translateY(calc(0.6px * var(--bias-y, 0))) translateX(calc(0.6px * var(--bias-x, 0))) translateX(-15px);
    will-change: transform;
    mask-image: linear-gradient(#000 80%, #0000);

    @include min(tablet-portrait) {
      transform: translateY(calc(0.6px * var(--bias-y, 0))) translateX(calc(0.6px * var(--bias-x, 0))) translateX(-30px);
    }

    @include max(tablet-landscape) {
      aspect-ratio: 16 / 12;
    }

    @include min(tablet-landscape) {
      position: absolute;
      inset: min(15vmin, 150px) min(5vmin, 50px) 0 min(8vmin, 80px);
      margin: auto;
    }
  }

  &-bo {
    @include max(tablet-landscape) {
      aspect-ratio: 16 / 12;
    }

    position: absolute;
    inset: min(15vmin, 150px) min(5vmin, 50px) 0 min(8vmin, 80px);
    margin: auto;
    pointer-events: none;
    background-image: url("../images/png/banner_bo.png");
    background-size: contain;
    background-position: left bottom;
    background-repeat: no-repeat;
  }
}

:root {
  // z-orders
  --z-index-header: 1;
  --z-index-take-bonus: 2;
  --z-index-banner-intro: 2;
  --z-index-widget-signup: 4;

  // colors
  --c-white: rgb(255 255 255);
  --c-ebony-black: rgb(34 34 34);
  --c-black-sunset: rgb(10 0 23);
  --c-bright-violet: rgb(170 0 255);
  --c-bright-green: rgb(189 255 0);
  --c-lime: rgb(255 229 0);
  --c-ultramarine-blue: rgb(38 115 225);
  --c-spanish-gray: rgb(156 156 156);

  // offsets
  --v-offset-sm: min(50px, 3vmax);
  --v-offset-md: min(100px, 5vmax);
  --v-offset-lg: min(160px, 8vmax);

  @media (width >= 1200px) {
    --v-offset-sm: min(40px, 2vmax);
    --v-offset-md: min(80px, 4vmax);
  }

  // NPM package typographics
  --max-vw: 1600;
  --min-vw: 400;
  --max-fs: 1;
  --min-fs: .75;
  --font-family-base: "Roboto Condensed", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --line-height-base: 1.5;
  --line-height-heading: 1.3em;

  // scrollbar colors
  --c-scrollbar-track: var(--c-black-sunset);
  --c-scrollbar-thumb: var(--c-white);
  --c-scrollbar-hover: var(--c-bright-green);
}

.take-bonus {
  position: relative;
  display: grid;
  place-content: center;
  z-index: var(--z-index-take-bonus);

  @include min(tablet-landscape) {
    position: absolute;
    inset: 0 0 0 auto;
    z-index: 4;
    margin: auto;
    width: 36vw;
    background: linear-gradient(to left, var(--c-black-sunset), var(--c-ebony-black));
    opacity: 1;
    transition: opacity 725ms cubic-bezier(0.5, 0, 0, 1);

    body.is-taking-bonus & {
      pointer-events: none;
      opacity: 0;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 0 var(--v-offset-md);

    .display-medium {
      margin: 0;
      color: var(--c-white);
      font-size: 6.2em;
      font-weight: 700;
      text-transform: uppercase;
      text-align: center;

      @include min(tablet-landscape) {
        padding: var(--v-offset-sm) 0;
        order: 1;

        &::before {
          content: attr(data-text) '\A';
          white-space: pre;
        }
      }
    }

    .display-large {
      margin: 12px 0 0;
      line-height: 1;
      text-transform: uppercase;
      color: transparent;
      background: linear-gradient(-190deg, var(--c-bright-green), var(--c-lime));
      background-clip: text;
      background-clip: text;
      font-size: 14.4em;
      font-style: italic;
      font-weight: 900;

      @include min(tablet-landscape) {
        margin-top: var(--v-offset-md);
        order: 3;
      }
    }

    .display-small {
      margin: 0;
      text-transform: uppercase;
      color: var(--c-white);
      font-weight: 860;

      @include min(tablet-landscape) {
        order: 4;
      }
    }

    .btn--take-bonus {
      margin-top: 26px;

      @include min(tablet-landscape) {
        margin-top: var(--v-offset-md);
        order: 5;
      }
    }

    .bonus-transfer {
      margin-top: 26px;

      @include min(tablet-landscape) {
        margin-top: calc(var(--v-offset-md) * 0.4);
        order: 2;
      }
    }
  }
}

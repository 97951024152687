.display-large {
  margin: 20px 0 0;
  font-weight: 900;
  color: darkgray;
}

.list-display {
  display: grid;
  gap: 20px 0;
  padding: max(3vmin, 20px);
  font-size: 16px;

  span {
    display: block;
    font-weight: 700;
  }
}
